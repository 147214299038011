// 
// 
// decorations.scss
//
//

.decoration{
	&:not(.position-relative){
		position: absolute;
	}
	svg{
		background: none !important;
	}
	&.top{
		top: 0;
	}
	&.left{
		left: 0;
	}
	&.right{
		right: 0;
	}
	&.middle-y{
		top: 50%;
	}
	&.middle-x{
		left: 50%;
	}
	&.bottom{
		bottom: 0;
	}
	@for $i from 2 through 5 {
		&.scale-#{$i}{
			svg{
				transform: scale(#{$i});
			}
		}
  }
}

.decoration-check{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	.decoration{
		position: relative;
		max-width: 250px;
	}
	.icon{
		position: absolute;
		height: 4rem;
	}
}

.mobile-screenshot{
	position: relative;
	> img{
		&:first-child{
			padding: 7%;
		}
		&:last-child{
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}
}