// 
// 
// images.scss
//
//

img[class*='flex-'][class*='-grow-1']{
  object-fit: cover;
}

img{
  max-width: 100%;
}