// 
// 
// reading-position.scss
// 
// Credit to CSS tricks: https://css-tricks.com/reading-position-indicator/#article-header-id-13
//
//

progress.reading-position {
  transition: opacity $transition-time ease;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  color: theme-color('primary-2');
  &.reading-position-hidden{
    opacity: 0;
  }
}

progress.reading-position::-webkit-progress-bar {
  background-color: transparent;
}

progress.reading-position::-webkit-progress-value {
  background-color: theme-color('primary-2');
}

progress.reading-position::-moz-progress-bar {
  background-color:theme-color('primary-2');
}

.article-progress{
  width: 100%;
  position: fixed;
  z-index: (($zindex-dropdown)-2);
  .article-progress-wrapper{
    background: $white;
    box-shadow: $box-shadow;
    transition: opacity $transition-time ease;
    border-bottom: $border-width solid $border-color;
  }
  .reading-position-hidden, .reading-position[value="0"]{
    + .article-progress-wrapper{
      opacity: 0;
      pointer-events: none;
      height:0;
    }
  }
}

.navbar-container:not([style]){
  + .article-progress{
    top: 0;
  }
}