// 
// 
// navbar.scss
//
//

.navbar{
  border-bottom: $border-width solid rgba($primary-3,.08);
  width: 100%;
  &[data-overlay]{
    position: absolute;
  }
  &[data-sticky="top"]{
    transition: background-color $transition-time ease;
    &.scrolled{
      &.navbar-dark{
        background: theme-color('primary-3');
        border-bottom-color: rgba(0,0,0,0);
      }
      &.navbar-light{
        background: $white !important;
      }
    }
  }
  &[data-overlay],&[data-sticky="top"]{
    z-index: (($zindex-dropdown)-1);
    &.scrolled{
      z-index: $zindex-tooltip;
    }
  }
  .dropdown-menu{
    z-index: $zindex-popover;
  }
  .nav-link{
    transition: color $transition-time ease;
  }
}

.navbar-container{
  position: relative;
}

.navbar-light{
  .navbar-toggler{
    svg{
      path{
        fill: theme-color('dark');
      }
    }
  }
}

.navbar-dark{
  border-bottom: $border-width solid rgba($white,.08);
  .navbar-toggler{
    svg{
      path{
        fill: $white;
      }
    }
  }
}

.navbar-toggler{
  border: none;
}

@include media-breakpoint-up(lg){
  .navbar{
    padding-left: 0;
    padding-right: 0;
    .dropdown-menu{
      margin-top: 1rem;
    }
  }
}

nav[data-overlay].navbar-toggled-show{
  background: $white;
  &.navbar-dark{
    background: theme-color('primary-3');
  }
}

@include media-breakpoint-down(md){
  .navbar-collapse{
    max-height: 100vh;
    overflow-y: scroll;
  }
}