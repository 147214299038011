// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.btn-primary {
color: #fff;
background-color: #38D39F;
border-color: #38D39F; }
.btn-primary:hover {
color: #fff;
background-color: #37e5a8;
border-color: #37e5a8; }
.btn-primary:focus, .btn-primary.focus {
box-shadow: 0 0 0 0.2rem #37e5a8; }

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #26d497;
  border-color: #26d497;
}
.text-green {
  color: #26d497 !important;
}
.fs-3rem {
font-size: 3rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  outline: none;
}