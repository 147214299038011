// --------------------------------------------------------------
// Video styles
// --------------------------------------------------------------

.plyr--video {
    background: #000;
    overflow: hidden;

    // Menu open
    &.plyr--menu-open {
        overflow: visible;
    }
}

.plyr__video-wrapper {
    background: #000;
    border-radius: inherit;
    overflow: hidden;
    position: relative;
    // Require z-index to force border-radius
    z-index: 0;
}
