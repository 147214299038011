// 
// 
// badge.scss
//
//

.badge{
  text-transform: uppercase;
  position: relative;
  bottom: 1px;
  vertical-align: middle;
  .icon{
    height: .75rem;
    vertical-align: top;
  }
  &.position-absolute{
    bottom: auto;
  }
  &.badge-top{
    position: absolute;
    top: -$spacer/2;
    bottom: auto;
  }
}