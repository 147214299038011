// 
// 
// progress.scss
//
//

.progress-bar{
  &:only-child{
    border-radius: $progress-border-radius;
  }
  font-weight: $font-weight-bold;
}

.progress{
  height: auto;
  min-height: $progress-height;
}