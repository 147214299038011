// 
// 
// tooltips.scss
//
//

.tooltip{
  font-weight: $font-weight-bold;
}

