// 
// 
// flickity.scss
//
//

@import "CSS:../plugins/flickity";

[data-flickity]{
  &:not(.flickity-enabled){
    opacity: 0;
  }
}

.flickity-page-dots{
  bottom: -2.125rem;
  .dot{
    width: 6px;
    height: 6px;
    border-radius: 10rem;
    margin: 0 5px;
    background: $body-color;
    opacity: .2;
    transition: transform $transition-time linear, opacity $transition-time linear;
    &.is-selected{
      transform: scale(1.33333333);
    }
  }
}

.flickity-prev-next-button{
  padding: 0;
  background: $white;
  border: 1px solid $border-color;
  width: $spacer*2;
  height: $spacer*2;
  transition: opacity $transition-time linear, box-shadow $transition-time linear;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after{
    background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.8517 18.1136C14.9506 18.2121 15 18.3402 15 18.4977C15 18.6356 14.9506 18.7538 14.8517 18.8523C14.7331 18.9508 14.6045 19 14.4661 19C14.3277 19 14.209 18.9508 14.1102 18.8523L8.14831 12.8841C8.04944 12.7659 8 12.6379 8 12.5C8 12.3621 8.04944 12.2341 8.14831 12.1159L14.1102 6.14773C14.209 6.04924 14.3277 6 14.4661 6C14.6045 6 14.7331 6.04924 14.8517 6.14773C14.9506 6.24621 15 6.37424 15 6.53182C15 6.6697 14.9506 6.78788 14.8517 6.88636L9.42373 12.5L14.8517 18.1136Z' fill='#{$primary-escaped}'/></svg>");
    content: '';
    width: 2rem;
    height: 2rem;
    display: block;
    background-size: cover;
  }
  &.previous{
    left: -$spacer*3;
  }
  &.next{
    right: -$spacer*3;
    &:after{
      transform: rotateZ(180deg);
    }
  }
  &:hover{
    box-shadow: $box-shadow-sm;
  }
}

.flickity-button-icon{
  display: none;
}

@include media-breakpoint-down(lg){
  .flickity-prev-next-button{
    display: none;
  }
}

[data-flickity]{
  &.controls-light{
    .flickity-page-dots{
      .dot{
        background: $white;
      }
    }
  }
}

.controls-inside{
  &:not(:hover){
    .flickity-prev-next-button{
      opacity: 0;
    }
  }
  .flickity-page-dots{
    position: absolute;
    bottom: $spacer;
  }
}

.controls-inside, .arrows-inside{
  .flickity-prev-next-button{
    &.previous{
      left: $spacer;
    }
    &.next{
      right: $spacer;
    }
  }
}

.carousel-cell{
  width: 100%;
}

.highlight-selected{
  .carousel-cell{
    transition: opacity $transition-time ease, transform $transition-time ease;
    &:not(.is-selected){
      transform: scale(0.9);
      opacity: .25;
    }
  }
}

// Phone App Screenshot slider

.slider-phone{
  > img{
    display: none;
    pointer-events: none;
  }
}

@include media-breakpoint-up(md){
  .slider-phone{
    position: relative;
    > img{
      position: absolute;
      display: block;
      z-index: 1;
      left: 50%;
      transform: translate3d(-50%,0,0);
    }
    .carousel-cell{
      img{
        transition: $transition-time opacity ease, $transition-time transform ease;
        transform: scale(0.86) translate3d(0,-4.55%,0);
      }
      &:not(.is-selected){
        img{
          opacity: .1;
          transform: scale(0.8);
        }
      }
    }
    .flickity-viewport{
      overflow: visible;
    }
  }
}


@include media-breakpoint-up(lg){
  .slider-phone{
    .carousel-cell{
      img{
        border-radius: $spacer;
      }
    }
  }
}
