// ==========================================================================
// Menus
// ==========================================================================

$plyr-menu-bg: rgba(#fff, 0.9) !default;
$plyr-menu-color: $plyr-color-fiord !default;
$plyr-menu-arrow-size: 6px !default;
$plyr-menu-border-color: $plyr-color-heather !default;
$plyr-menu-border-shadow-color: #fff !default;
$plyr-menu-shadow: 0 1px 2px rgba(#000, 0.15) !default;
